
.carousel-caption{
    left:0;
    right:0;
    hyphens: none;
    & > span {
        max-width: 500px;
    }
}
.carousel{
    &::after{
        content: "";
        display: block;
        position: absolute;
        width: clamp(100px, 33vw, 340px);
        background: url('../assets/img/small_logo.svg');
        background-repeat: no-repeat;
        background-size: contain;
        aspect-ratio: 1/1;
        right: 0;
        bottom: clamp(-20px, -2vw, -5px);
        opacity: .3;
        z-index: 4;
    }
}
#splash-wrap{
    min-height: 300px;
    height: 30vw;
    max-height: 538;
    position: relative;
    overflow: hidden;
    background-color: rgba(130,130,130,.5);
    
    .splash-graphic{
        mix-blend-mode: multiply;
    }
}
 
.carousel-item{
    background-color: rgba(126, 126, 126, .7);
    
    &> picture > img{
        mix-blend-mode: multiply;
        pointer-events: none;
    }
    
}   
.carousel-indicators{
    bottom: calc(1vh + 5vw);
    justify-content: start;
    gap: .75rem;
    width: 100%;
    max-width: 1800px;
    padding:0 calc(var(--bs-gutter-x) * 0.5);
    margin:0;
    [data-bs-target] {
        background-color: #A3BBB7;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        pointer-events:all;
        opacity: 1;
        transition: transform .15s ease, opacity .15s ease, height .15s ease;
        &:hover{
            transform: scale(1.1);
            opacity: 1;
            
        }
        &.active{
            background-color: #FFFFFF;
        }
      
    }
    @include media-breakpoint-down(sm) {
        display: none;
    }
}