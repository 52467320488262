
// Author's custom styles
// we need to import this first so that it would override bootstrap scss variables later

@import '@splidejs/splide/css';
@import "variables";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/plyr/dist/plyr.css";

// Our own scss below
@import "header";
@import "splash";
@import "page";
@import "block";
@import "footer";
@import "form";
@import "glightbox/dist/css/glightbox.css";
.proxima-nova{
    font-family: $font-primary;
}

.bree{
    font-family: $font-bree;
    font-weight: 400;
} 

.img-tint{
    background-color: rgba(130, 130, 130, .5);
    transition: background-color 1s ease;
    img{
        mix-blend-mode: multiply;
    }
}

.img-tint-hover:hover .img-tint{
    background-color:transparent;
}

.row-list{
    padding:0;
    margin:0;
    display: flex;
    justify-content: flex-start;
    list-style: none;
    gap:1rem;
    width: 100%;

}

.two-col{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    padding: 0;
    margin: 0;
    list-style: none;
}

.page-item{
    font-weight: 600;
    &.active{
        border-bottom: 3px solid $green;
    } 
}

.pager-arrows.disabled{
    display: none;
}

.pager-arrow-a{
    display: flex;
    gap: 1rem;
}

.link-hover{
    border-bottom: 3px solid transparent;
    transition: border .3s ease;
    font-weight: 600;
    padding-bottom: .25rem;
    &:hover, &.active{
        border-bottom: 3px solid $green;
    }
}

.hover-dark-green{
    transition: color .3s ease, background-color .3s ease;
    &:hover{
        color:white!important;
        background-color: $dark-green;
        border-color: $dark-green;
    }
}

.hover-orange{
    transition: color .3s ease, background-color .3s ease;
    &:hover{
        color:white!important;
        background-color: $orange;
        border-color: $orange;
    }
}


.hover-text-white{
    transition: color .3s ease;
    &:hover{
        color:white!important;
    }
}
// //bootstrap class overrider
.btn-primary{
    transition: all .3s ease;
    color:white;
    &:hover{
        --bs-btn-hover-bg: var(--bs-green);
        --bs-btn-hover-border-color: var(--bs-green);
        color:var(--bs-black)!important;
    }
}

.btn-dark-green{
    transition: all .3s ease;
    color:white;
    &:hover{
        --bs-btn-hover-bg: var(--bs-green);
        --bs-btn-hover-border-color: var(--bs-green);
        color:var(--bs-black)!important;
    }
}

.ratio-4x3 {
    --bs-aspect-ratio: 65%;
}

.btn.small{
    font-size: 16px!important;
}
.btn-link{
    border-bottom: 1px solid;
    border-radius: 0;
    padding-left: 5px;
    padding-right: 5px;
}

.has-collapse-button .btn-link{
    border-bottom: 1px solid transparent!important;
}

blockquote{
    font-size: 2.4rem;
    color: $dark-green;
    font-family: $font-bree;
}

select{
    word-wrap: normal;
    background: transparent;
    padding: 0.5rem;
    border:0;
    border-bottom: 3px solid $green;
}

iframe{
    width: 100%;
}

ul{
    li{
        margin-bottom: .5rem;
    }
}
.btn{
    letter-spacing: 2px;
}
.office-icon{
    min-height: 300px;
}
.nav-link{
    letter-spacing: 2px;
}