@import "variables";
.t-home{
    background-color: #F2EAE6;
    #splash-wrap{
        min-height: 600px;
        height: 100vh;
        max-height: 100vh;
    }
}
.t-insights{
    #filter_insights{
        a{
            border-bottom: 3px solid transparent;
            transition: border .3s ease;
            color: $dark-green;
            font-weight: 600;
            &:hover, &.active{
                border-bottom: 3px solid $green;
            }
        }
    }
    .view-option{
        img{
            transition: filter .3s ease;
            cursor: pointer;
            filter: brightness(3)grayscale(1);
            &:hover, &.active{
                filter:brightness(1)grayscale(0);
            }
        }
    }
}
.t-research{
    .view-option{
        img{
            transition: filter .3s ease;
            cursor: pointer;
            filter: brightness(3)grayscale(1);
            &:hover, &.active{
                filter:brightness(1)grayscale(0);
            }
        }
    }
}
.share-bar{
    svg{
        height: 25px;
        width: 25px;
        object-fit: contain;
    }
}
.t-contact, .t-office{
    .contact-icon{
        svg{
            height: 30px;
            width: 30px;
            object-fit: contain;
        }
    }
    .office-contact-icon{
        height: 25px;
        width: 25px;
        min-width: 25px;
        svg{
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
    .site-links a svg {
        height: 30px;
        width: 30px;
    }
    .office-item{
        display: flex;
        flex-direction: column;
        .office-btn{
            margin-top: auto;
            opacity: 0;
            transition: all .3s ease;
            padding: 10px 12px;
          
        }
        &:hover{
            .office-btn{
                opacity: 1;
            }
            
        }
    }
   .office-infowindow{
        width: clamp(400px, 25vw, 475px);
        height: auto;
        left: auto;
        right: 5vw;
        top: 2vw;
        display: none;
        @include media-breakpoint-down(lg) {
            position: relative!important;
            width:100%!important;
            left:unset;
            right: unset;
            top: unset;
            padding-bottom: 2rem;
            min-height: 100%;
            border-radius: 0!important;
        }
   }
   .map-wrapper{
        @include media-breakpoint-down(xl) {
            --bs-aspect-ratio: 55%;
        }
        @include media-breakpoint-down(lg) {
            --bs-aspect-ratio: 100%;
        }

   }
}
