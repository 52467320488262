@import "variables";

.block-wrapper{
    background-color: $off-white;
}

.block-image-content{
    @include media-breakpoint-down(md) {
        .row-list{
            justify-content: center;
        }
    }
}

.block-cta-2-column, .block-cta-3-column{
    .img-tint-hover{
        overflow: hidden;
        img{
            transition: transform 1s ease-in-out;
        }
        &:hover img{
            transform: scale(1.1);
        }
    }
}
.block-video-block{
    overflow: hidden;
    --plyr-color-main:var(--bs-green);
    .plyr__control.plyr__control--overlaid{
        left:45%;
        top: 75%;
        &::after{
            content: "PLAY VIDEO";
            color: $green;
            white-space: nowrap;
            position: absolute;
            left: calc(100% + 20px);
            top: 5px;
        }
    }
    .plyr{
        transition: transform .3s ease;
        &.plyr--stopped{
            &:hover{
                transform: scale(1.1);
            }
        }
     
       
    }
    .plyr__poster{
        background-size: cover;
    }
    .plyr__video-wrapper.plyr__video-embed{
        aspect-ratio: 7.11 / 3!important;
    }
 
}
.block-offices, .block-testimonials{
    .splide-nav {
        padding: 10px 0;
        border:1px solid transparent;
        transition: border .3s ease;
        &:hover{
            border-bottom:1px solid $dark-green;
        }
    }
    .splide__pagination{
        bottom: -3em;
        gap:.75rem;
        .splide__pagination__page{
            background: #A3BBB7;
            &.is-active{
                background: $dark-green;
                transform: none;
            }
        }
    }
}