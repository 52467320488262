@import "variables";

.cta-footer{
    a h3{
        transition: color 1s ease;
    }
    a:hover h3{
        color:$dark-green!important;
    }
}


#footer{
    position: relative;
    &::after{
        content: "";
        display: block;
        position: absolute;
        width: clamp(100px, 25vw, 290px);
        background: url('../assets/img/small_logo_green.svg');
        background-repeat: no-repeat;
        background-size: contain;
        aspect-ratio: 1/1;
        right: 0;
        bottom: clamp(-20px, -2vw, -5px);
    }
}
footer{
    background-color: $dark-green;
    .logo{
        max-width: none;
        width: clamp(80px, 20vw, 120px);
        height: auto;
        @include media-breakpoint-down(xl) {
           margin-left: auto;
           margin-right: auto;
        }
    }
    .footer-contact{
        @include media-breakpoint-down(xl) {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        } 
    }

    .footer-menu{
        .row-list{
            width: auto;
            align-items: center;
        }
        .nav-item{
            display: flex;
            align-items: center;
        }
    }
    .site-map{
        ul.nav{
            gap:1rem;
            .nav-link{
                border-bottom: 3px solid transparent;
                padding-left:5px;
                padding-right:5px;
                &:hover{
                    border-bottom: 3px solid $green;
                }
            }
        }
    }
    .site-links{
        a svg{
            transition: fill .3s ease;
            &:hover{
                fill: $white;
            }
        }
        svg{
            width: 30px;
            height: 30px;
            object-fit: contain;
        }
    }
}
.cta-bg{
    transition: background-color .3s ease;
    &:hover{
        background-color: $green!important;
    }
}
